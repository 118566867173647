<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 10 }"
    >
      <a-form-item label="类型">
        {{info.type == 2 ? '申报' : '投保'}}
      </a-form-item>
      <a-form-item label="姓名">
        {{info.name}}
      </a-form-item>
      <a-form-item label="手机号">
        {{info.tel}}
      </a-form-item>
      <a-form-item label="充值金额">
        <a-input-number
          style="width: 120px"
          v-model="money"
        ></a-input-number>
        元
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 5 }">
        <a-button type="primary" @click="sub">提交充值</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      info: {},
      form: {
        type: 1,
        rate: 15
      },
      money: null
    };
  },
  async created () {
    const customer_id = this.$route.query.id;
    const r = await this.$axios.get("/customer/getInfo?id=" + customer_id);
    console.log(r);
    this.info = r.info
  },
  methods: {
    async sub () {
      const r = await this.$axios.post('/customer/recharge', {
        who: this.info.who,
        money: this.money
      })

      if(r.status == 1) this.$router.back()
    }
  },
};
</script>