<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 10 }"
    >
      <a-form-item label="姓名">
        <a-input
          style="width: 60%"
          v-model="form.name"
          placeholder="请输入姓名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="用户名">
        <a-input
          style="width: 60%"
          v-model="form.username"
          placeholder="请输入用户名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="密码">
        <a-input
          type="password"
          style="width: 60%"
          v-model="form.password"
          placeholder="请输入密码"
        ></a-input>
      </a-form-item>
      <a-form-item label="密码">
        <a-input
          type="password"
          style="width: 60%"
          v-model="form.password2"
          placeholder="再次输入密码"
        ></a-input>
      </a-form-item>

      <a-form-item label="备注">
        <a-textarea
          style="width: 60%"
          v-model="form.desc"
          placeholder="请输入备注"
          :auto-size="{ minRows: 3, maxRows: 6 }"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 5 }">
        <a-button type="primary" @click="sub">提交</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      form: {
        id: null,
      },
    };
  },
  created() {
    this.form.id = this.$route.query.id;
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      const r = await this.$axios.get("/user/getUserInfo?id=" + this.form.id);
      if (r.status == 1) this.form = r.info;
    },
    async sub() {
      console.log(this.form);
      if (!this.form.name) return this.$message.error("请输入姓名");
      if (!this.form.username) return this.$message.error("请输入用户名");
      if (this.form.password) {
        if (this.form.password.length < 6)
          return this.$message.error("请输入6位数以上密码");
        if (!this.form.password2) return this.$message.error("请再次输入密码");
        if (this.form.password != this.form.password2)
          return this.$message.error("两次输入的密码不一致");
      }
      // 提交表单
      const r = await this.$axios.post("/user/update", this.form);

      if (r.status == 1) {
        this.$router.push("/user/list");
      }
    },
  },
};
</script>