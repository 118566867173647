import { render, staticRenderFns } from "./update.vue?vue&type=template&id=3457b127&scoped=true"
import script from "./update.vue?vue&type=script&lang=js"
export * from "./update.vue?vue&type=script&lang=js"
import style0 from "./update.vue?vue&type=style&index=0&id=3457b127&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3457b127",
  null
  
)

export default component.exports