<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      <a-form-item label="类型">
        <a-radio-group v-model="options.type">
          <a-radio :value="0">所有</a-radio>
          <a-radio :value="1">投保</a-radio>
          <a-radio :value="2">申报</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="上级">
        <a-input
          style="width: 200px"
          placeholder="请输入上级姓名"
          v-model="options.parent"
        ></a-input>
      </a-form-item>

      <a-form-item label="姓名">
        <a-input
          style="width: 200px"
          placeholder="请输入姓名"
          v-model="options.name"
        ></a-input>
      </a-form-item>
      <a-form-item label="手机">
        <a-input
          style="width: 200px"
          placeholder="请输入号码"
          v-model="options.tel"
        ></a-input>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="search">搜索</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ page.amount }}个</div>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <template slot="type" slot-scope="text, record">
        <a-tag v-if="record.type1">投保</a-tag>
        <a-tag v-if="record.type2">申报</a-tag>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag color="blue" v-if="record.status == 1">正常</a-tag>
        <a-tag color="red" v-else>禁用</a-tag>
      </template>
      <template slot="xiaji" slot-scope="text, record">
        <a-button size="small" @click="addXiaji(record.id)">添加</a-button>
      </template>

      <template slot="action" slot-scope="text, record">
        <router-link :to="'/customer/recharge?id=' + record.id">
          <a-button size="small" type="primary">充值</a-button>
        </router-link>
        <a-divider type="vertical" />
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item
              key="1"
              @click="goto('/customer/info?id=' + record.id)"
            >
              用户资料
            </a-menu-item>
            <a-menu-item
              key="2"
              @click="goto('/recharge/list?who=' + record.who)"
            >
              充值记录
            </a-menu-item>
            <a-menu-item key="3" @click="goto('/order/list?who=' + record.who)">
              投保记录
            </a-menu-item>
          </a-menu>
          <a-button size="small"> 查看<a-icon type="down" /> </a-button>
        </a-dropdown>
        <a-divider type="vertical" />
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item
              key="1"
              @click="goto('/customer/update?id=' + record.id)"
            >
              编辑
            </a-menu-item>
            <a-menu-item
              key="2"
              v-if="record.status == 1"
              @click="updateStatus(record.id, 0)"
            >
              禁用
            </a-menu-item>
            <a-menu-item key="4" v-else @click="updateStatus(record.id, 1)">
              启用
            </a-menu-item>

            <a-menu-item key="3" @click="updateStatus(record.id, -1)"> 删除 </a-menu-item>
          </a-menu>
          <a-button size="small"> 操作<a-icon type="down" /> </a-button>
        </a-dropdown>
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="50"
      :total="page.amount"
      @change="getList"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "类型",
    scopedSlots: {
      customRender: "type",
    },
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "电话",
    dataIndex: "tel",
  },
  {
    title: "登录账号",
    dataIndex: "username",
  },

  {
    title: "余额",
    dataIndex: "balance",
  },
  {
    title: "添加时间",
    dataIndex: "created_at",
  },
  // {
  //   title: "最后投保时间",
  //   dataIndex: "last_insured_at",
  // },
  {
    title: "状态",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "上级",
    dataIndex: "parent",
  },
  {
    title: "下级",
    scopedSlots: {
      customRender: "xiaji",
    },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];

export default {
  data() {
    return {
      columns,
      list: [],
      options: {
        type: 0,
      },
      page: {
        now_page: 1,
        page_size: 50,
        amount: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    addXiaji (id) {
      this.$router.push('/customer/create?pid=' + id)
    },
    search() {
      this.page.now_page = 1;
      this.getList();
    },

    async getList() {
      const options = { ...this.options };
      options.page = this.page.now_page;
      const r = await this.$axios.post("/customer/getList", options);

      if (r.status != 1) return;

      this.list = r.list;
      this.page.amount = r.amount;
    },

    goto(url) {
      this.$router.push(url);
    },

    async updateStatus(id, status) {
      const r = await this.$axios.post("/customer/updateStatus", {
        id,
        status,
      });

      if (r.status == 1) this.getList();
    },
  },
};
</script>