<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      <a-form-item label="应用">
        <a-select style="width: 130px" placeholder="应用筛选" v-model="options.app_id" allowClear>
          <a-select-option
            v-for="(row, index) in appList"
            :key="index"
            :value="Number(row.id)"
            >{{ row.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="保单号">
        <a-input
          style="width: 200px"
          placeholder="请输入保单号"
          v-model="options.policyno"
        ></a-input>
      </a-form-item>


      <a-form-item label="投保时间">
        <a-date-picker v-model="options.start_at" allowClear /> -
        <a-date-picker v-model="options.end_at" allowClear />
      </a-form-item>

      <a-form-item label="状态">
        <a-select style="width: 130px" placeholder="状态筛选" v-model="options.status" allowClear>
          <a-select-option
            v-for="(status, index) in statusList"
            :key="index"
            :value="status.id"
            >{{ status.name }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="getOrderList">搜索</a-button>
        <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ page.amount }}条，保额{{page.money}}元，保费{{page.fee}} 元</div>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >

  
      <template slot="place" slot-scope="text, record">
        {{ record.fromPortDesc }} -- {{ record.toPortDesc }}
      </template>

      <template slot="qiyun" slot-scope="text, record">
        {{ record.cargoFromDate }} :00
      </template>

      <template slot="status" slot-scope="text, record">
        <a-tag color="green" v-if="record.rescode==0">调用成功</a-tag>
        <a-tag color="red" v-if="record.rescode==1">调用失败</a-tag>
      </template>

      <template slot="action" slot-scope="text, record">
         <a-button size="small" @click="record.policyno">查看保单</a-button>
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="50"
      :total="page.amount"
      @change="getOrderList"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "应用",
    dataIndex: "app_name",
  },
  {
    title: "险种",
    dataIndex: "plan",
  },
  {
    title: "货物",
    dataIndex: "cargoDesc",
  },
  {
    title: "区间",
    scopedSlots: {
      customRender: "place",
    },
  },
  {
    title: "车牌号",
    dataIndex: "conveyance",
  },
  {
    title: "保额",
    dataIndex: "totalInsuredAmount",
  },
  {
    title: "保费",
    dataIndex: "price",
  },
  {
    title: "投保时间",
    dataIndex: "create_time"
  },
  {
    title: "起运时间",
    scopedSlots: {
      customRender: "qiyun",
    },
  },
  {
    title: "状态",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      statusList: [
        { id: 1, name: "投保提交成功" },
        { id: 2, name: "投保提交失败" }
      ],
      appList:[],
      options: {
        policyno: "",
        start_at: null,
        end_at: null
      },
      columns,
      list: [],
      amount: 0,
      money: 0,
      page: {
        now_page: 1,
      },
    };
  },
  created() {
    if(this.$route.query.appid) this.options.app_id = Number(this.$route.query.appid);
    this.getOrderList();
  },
  methods: {
    clear () {
      this.options = {
        policyno: "",
        start_at: null,
        end_at: null
      }
      this.getOrderList()
    },
    view(no){
        let url = 'http://pa.baoyuntong.com.cn/pa18/cargo/index.php?m=download&policyNo='+no;
        window.location.href = url;
    },
    async getOrderList() {
      const options = { ...this.options };
      options.page = this.page.now_page || 1;
      const r = await this.$axios.post("/openapi/logs", options);
      this.list = r.list;
      this.page = r.pages;
      this.money = r.money;
      this.appList = r.appList;
    },
    async exportData () {
      const r = await this.$axios.post("/openapi/export", this.options);

      if(r.status == 1){
        window.open(r.file)
      }
    }
  },
};
</script>