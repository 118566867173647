<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 10 }"
    >
      <!-- <a-form-item label="类型">
        <a-radio-group v-model="form.type">
          <a-radio :value="10">管理员</a-radio>
          <a-radio :value="1">保单管理</a-radio>
          <a-radio :value="2">充值管理</a-radio>
          <a-radio :value="3">客户管理</a-radio>
        </a-radio-group>
        <i class="must">*</i>
      </a-form-item> -->
      <a-form-item label="姓名">
        <a-input
          style="width: 60%"
          v-model="form.name"
          placeholder="请输入姓名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="用户名">
        <a-input
          style="width: 60%"
          v-model="form.username"
          placeholder="请输入用户名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="初始密码">
        <a-input
          type="password"
          style="width: 60%"
          v-model="form.password"
          placeholder="请输入密码"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="确认密码">
        <a-input
          type="password"
          style="width: 60%"
          v-model="form.password2"
          placeholder="再次输入密码"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <!-- <a-form-item label="手机号">
        <a-input
          style="width: 60%"
          v-model="form.tel"
          placeholder="请输入手机号"
        ></a-input>
      </a-form-item> -->

      <!-- <a-form-item label="Email">
        <a-input
          style="width: 60%"
          v-model="form.email"
          placeholder="请输入Email"
        ></a-input>
      </a-form-item> -->

      <a-form-item label="备注">
        <a-textarea style="width: 60%" v-model="form.desc" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 5 }">
        <a-button type="primary" @click="sub">提交</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      // groupList: [],
      form: {
        group_id: 1,
        name: "",
        username: '',
        password: "",
        password2: "",
        tel: '',
        email: '',
        desc: ''
      },
    };
  },
  created() {
    // this.getGroupList()
  },
  methods: {
    // async getGroupList () {
    //   const r = await this.$axios.get("/user/getGroupList");
    //   if(r.status == 1){
    //     this.groupList = r.list;
    //   }
    // },
    async sub() {
      console.log(this.form);
      // if (!this.form.group_id) return this.$message.error("请选择权限");
      if (!this.form.name) return this.$message.error("请输入姓名");
      if (!this.form.username) return this.$message.error("请输入用户名");
      if (this.form.password.length < 6) return this.$message.error("请输入6位数以上密码");
      if (!this.form.password2) return this.$message.error("请再次输入密码");
      if(this.form.password != this.form.password2) return this.$message.error("两次输入的密码不一致");

      // 提交表单
      const r = await this.$axios.post("/user/create", this.form);

      if (r.status == 1) {
        this.$router.push('/user/list')
      }
    },
  },
};
</script>