<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      <a-form-item label="类型">
        <a-radio-group v-model="options.type">
          <a-radio :value="0">全部</a-radio>
          <a-radio :value="1">投保</a-radio>
          <a-radio :value="2">申报</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="投保人">
        <a-input
          style="width: 200px"
          placeholder="请输入姓名"
          v-model="options.name"
        ></a-input>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          style="width: 200px"
          placeholder="请输入号码"
          v-model="options.tel"
        ></a-input>
      </a-form-item>

      <a-form-item label="投保时间">
        <a-date-picker v-model="options.start_at" /> -
        <a-date-picker v-model="options.end_at" />
      </a-form-item>

      <a-form-item label="状态">
        <a-select style="width: 130px" v-model="options.status">
          <a-select-option
            v-for="(status, index) in statusList"
            :key="index"
            :value="status.id"
            >{{ status.name }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="getOrderList">搜索</a-button>
        <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ page.amount }}条，保额{{money}}元，保费{{fee}} 元</div>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >

      <template slot="type" slot-scope="text, record">
        {{ record.type == 2 ? '申报' : '投保' }}
      </template>

      <template slot="place" slot-scope="text, record">
        {{ record.fromPortDesc }} -- {{ record.toPortDesc }}
      </template>

      <template slot="qiyun" slot-scope="text, record">
        {{ record.cargoFromDate }} {{ record.cargoFromDateHr }}:00
      </template>

      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status2.color">{{ record.status2.name }}</a-tag>
      </template>

      <template slot="action" slot-scope="text, record">
        <router-link :to="'/order/detail?id=' + record.id">
          <a-button size="small">查看</a-button>
        </router-link>
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="50"
      :total="page.amount"
      @change="getOrderList"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "类型",
    scopedSlots: {
      customRender: "type",
    },
  },
  {
    title: "投保人",
    dataIndex: "user_name",
  },
  {
    title: "手机",
    dataIndex: "user_tel",
  },
  {
    title: "险种",
    dataIndex: "plan",
  },
  {
    title: "货物",
    dataIndex: "cargoDesc",
  },
  {
    title: "区间",
    scopedSlots: {
      customRender: "place",
    },
  },
  {
    title: "车牌号",
    dataIndex: "conveyance",
  },
  {
    title: "保额",
    dataIndex: "totalInsuredAmount",
  },
  {
    title: "保费",
    dataIndex: "price",
  },
  {
    title: "投保时间",
    dataIndex: "created_at",
  },
  {
    title: "起运时间",
    scopedSlots: {
      customRender: "qiyun",
    },
  },
  {
    title: "状态",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      statusList: [
        { id: 100, name: "全部" },
        { id: 0, name: "已存储未投保" },
        { id: 1, name: "已扣除余额" },
        { id: 10, name: "已投保成功" },
        { id: -10, name: "已取消" },
        { id: -1, name: "扣款失败" },
        { id: -2, name: "投保失败" },
        { id: -3, name: "自动审核失败转人工" },
      ],
      options: {
        type: 0,
        name: "",
        tel: "",
        start_at: null,
        end_at: null,
        status: 100,
      },
      columns,
      list: [],
      amount: 0,
      money: 0,
      fee: 0,
      page: {
        now_page: 1,
      },
    };
  },
  created() {
    if(this.$route.query.who) this.options.who = this.$route.query.who;
    this.getOrderList();
  },
  methods: {
    clear () {
      this.options = {
        name: "",
        tel: "",
        start_at: null,
        end_at: null,
        status: 20,
      }
      this.getOrderList()
    },
    async getOrderList() {
      const options = { ...this.options };
      options.page = this.page.now_page || 1;
      const r = await this.$axios.post("/order/getList", options);
      this.list = r.list;
      this.page = r.pages;
      this.money = r.money;
      this.fee = r.fee;
    },
    async exportData () {
      const r = await this.$axios.post("/order/exportData", this.options);

      if(r.status == 1){
        window.open(r.file)
      }
    }
  },
};
</script>