<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <h4>账户信息</h4>
      <a-form-item label="上级代理">
        <router-link v-if="info.parent_id" target="_blank" :to="'/customer/info?id=' + info.parent_id">
          <a-button size="small">查看</a-button></router-link
        >
        <span v-else>无</span>
      </a-form-item>
      <a-form-item label="姓名">{{ info.name }}</a-form-item>
      <a-form-item label="手机">{{ info.tel }}</a-form-item>
      <a-form-item label="账号">{{ info.username }}</a-form-item>
      <a-form-item label="创建时间">{{ info.created_at }}</a-form-item>

      <hr />
      <h4>保险类型及保费</h4>
      <a-form-item label="账户余额">{{ info.balance }} 元</a-form-item>
      <a-form-item label="类型">
        <a-tag v-if="info.type1 == 1">投保</a-tag>
        <a-tag v-if="info.type2 == 1">申报</a-tag>
      </a-form-item>
      <a-form-item label="综合险费率">{{ info.rate2 }}</a-form-item>
      <a-form-item label="基本险费率">{{ info.rate1 }}</a-form-item>
      <a-form-item label="易碎险费率">{{ info.rate3 }}</a-form-item>

      <hr />
      <h4>投保人信息</h4>
      <a-form-item label="公司名称">{{ info.applicant_name }}</a-form-item>
      <a-form-item label="统一社会信用代码">{{ info.applicant_certificateNo }}</a-form-item>
      <a-form-item label="联系人">{{ info.applicant_linkManName }}</a-form-item>
      <a-form-item label="联系电话">{{ info.applicant_mobileTelephone }}</a-form-item>
      <a-form-item label="地址">{{ info.applicant_address }}</a-form-item>
      <a-form-item label="Email">{{ info.applicant_email }}</a-form-item>

      <hr />
      <h4>被保险人信息</h4>
      <a-form-item label="被保险人">{{ info.insurant_name }}</a-form-item>
      <a-form-item label="被保险人类型">
        {{ info.insurant_personnelType == '1' ? '个人' : '团体' }}
      </a-form-item>
      <a-form-item label="证件类型">{{ info.insurant_certificateType == '01' ? '身份证' : '统一社会信用代码证' }}</a-form-item>
      <a-form-item label="证件号码">{{ info.insurant_certificateNo }}</a-form-item>
      <a-form-item label="被保险人生日">{{ info.insurant_birthday }}</a-form-item>
      <a-form-item label="被保险人性别">
        <span v-if="info.insurant_sexCode == 'M'">男</span>
        <span v-if="info.insurant_sexCode == 'F'">女</span>
      </a-form-item>
      <a-form-item label="联系人">{{ info.insurant_linkManName }}</a-form-item>
      <a-form-item label="联系电话">{{ info.insurant_mobileTelephone }}</a-form-item>
      <a-form-item label="地址">{{ info.insurant_address }}</a-form-item>
      <a-form-item label="Email">{{ info.insurant_email }}</a-form-item>

      <hr />
      <h4>备注</h4>
      <a-form-item label="备注">{{ info.remarks }}</a-form-item>

    </a-form>
  </div>
</template>

<style lang="scss" scoped>
.form {
  padding: 40px;
}
.sec {
  margin: 15px;
}
img {
  max-width: 100%;
}

hr {
  border-top: none;
  margin: 40px;
}
h4 {
  margin-left: 40px;
}
</style>

<script>
export default {
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      info: {},
    };
  },
  async created() {
    const customer_id = this.$route.query.id;
    const r = await this.$axios.get("/customer/getInfo?id=" + customer_id);
    this.info = r.info;
    console.log(r.info);
  },
};
</script>