<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 10 }"
    >
     <a-form-item label="应用名称">
        <a-input
          style="width: 60%"
          v-model="form.name"
          placeholder="请输入应用名称"
        ></a-input>
         <i class="must">*</i>
      </a-form-item>
      <a-form-item label="APPID">
        <a-input
          style="width: 60%"
          v-model="form.appid"
          disabled
          placeholder="请输入appid"
        ></a-input>
        <a-button class="right-btn" type="primary" @click="genStr(16,'appid')">生成</a-button>

         <i class="must">*</i>
      </a-form-item>
      <a-form-item label="APPSECRET">
        <a-input
          style="width: 60%"
          v-model="form.appsecret"
          disabled
          placeholder="请输入appsecret"
        ></a-input>
        <a-button  class="right-btn"  type="primary" @click="genStr(32,'appsecret')">生成</a-button>

        <i class="must">*</i>
      </a-form-item>

      <a-form-item label="状态">
        <a-switch default-checked :checked="form.status==1" @change="onChange" />
        <i class="must">*</i>
      </a-form-item>
  
      <a-form-item label="备注">
        <a-textarea style="width: 60%" v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-item>

       <a-form-item label="IP白名单">
        <a-textarea style="width: 60%" v-model="form.whiteips" placeholder="多个以英文逗号分割" :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 5 }">
        <a-button type="primary" @click="sub">提交</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
.right-btn{
    margin-left: 5px;
}
</style>

<script>
export default {
  data() {
    return {
      // groupList: [],
      form: {
        id: 0,
        name: "",
        appid: "",
        appsecret: '',
        remark: "",
        status: 0,
        whiteips: ''
      },
    };
  },
  created() {
    let id = this.$route.query.id;
    if(id && Number(id) > 0){
        this.getDetail(id);
    }
  },
  methods: {
    onChange(checked) {
      this.form.status=checked?1:0;
    },
    genStr(len,key){
       len = len || 32;
        let chars = key=='appid'?'abcdefhijkmnprstwxyz123456789':'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        let maxPos = chars.length;
        let str = '';
        for (let i = 0; i < len; i++) {
            str += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        this.form[key] = str;
    },
    async getDetail(id) {
      const r = await this.$axios.post("/openapi/detail", {id:id});

      if (r.code == 0) {
        this.form = r.data;
      }else{
          this.$message.error(r.msg);
          setTimeout(()=>{
              this.$router.push('/openapi/list');
          },1500);
      }
    },
    async sub() {
      if (this.form.appid.length !== 16) return this.$message.error("请生成正确的appid");
      if (!this.form.name) return this.$message.error("请输入应用名称");
      if (this.form.appsecret.length !== 32) return this.$message.error("请生成正确的appsecret");
    
      // 提交表单
      const r = await this.$axios.post("/openapi/submit", this.form);

      if (r.code == 0) {
            this.$message.success(r.msg);
            setTimeout(()=>{
                this.$router.push('/openapi/list');
            },1500);
      }else{
           this.$message.error(r.msg);
      }
    },
  },
};
</script>