<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 10 }"
    >
      <a-form-item label="投保人">
        {{ list.user_name }}
      </a-form-item>
      <a-form-item label="电话">
        {{ list.user_tel }}
      </a-form-item>
      <a-form-item label="投保方案">
        {{ list.planindex }}
      </a-form-item>
      <a-form-item label="方案名称">
        {{ list.plan }}
      </a-form-item>
      <a-form-item label="保险金额">
        {{ list.totalInsuredAmount }}
      </a-form-item>
      <a-form-item label="起运时间">
        {{ list.cargoFromDate }} {{ list.cargoFromDateHr }}:00
      </a-form-item>
      <a-form-item label="起运地">
        {{ list.fromPortDesc }}
      </a-form-item>
      <a-form-item label="目的地">
        {{ list.toPortDesc }}
      </a-form-item>
      <a-form-item label="车牌号">
        {{ list.conveyance }}
      </a-form-item>
      <a-form-item label="货物描述">
        {{ list.cargoDesc }}
      </a-form-item>
      <a-form-item label="包装方式">
        {{ list.packagingMode }}
      </a-form-item>
      <a-form-item label="货物大类">
        {{ list.cargoTypeBig }}
      </a-form-item>
      <a-form-item label="被保险人">
        {{ list.InsuredCustomerName }}
      </a-form-item>
      <a-form-item label="被保险人联系人">
        {{ list.InsuredLinkManName }}
      </a-form-item>
      <a-form-item label="投保人联系人">
        {{ list.ProposerLinkManName }}
      </a-form-item>
      <a-form-item label="保费">
        {{ list.price }}
      </a-form-item>
      <a-form-item label="状态">
        {{ list.status }}
      </a-form-item>
      <a-form-item label="保单号">
        {{ list.sn }}
      </a-form-item>
      <a-form-item label="提交时间">
        {{ list.created_at }}
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: null,
      list: {},
    };
  },

  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    async getData() {
      const r = await this.$axios.get("/order/orderDetail/" + this.id);
      console.log(r);
      this.list = r;
    },
  },
};
</script>

