<template>
  <div class="page">
    <a-row class="row">
      <a-col :span="4" class="col">
        <a-statistic
          title="今日保单数"
          :value="today_amount"
          style="margin-right: 50px"
        />
      </a-col>
      <a-col :span="4" class="col">
        <a-statistic
          title="今日保额"
          :value="today_money"
          style="margin-right: 50px"
        />
      </a-col>
      <a-col :span="4" class="col">
        <a-statistic
          title="今日保费"
          :value="today_fee"
          style="margin-right: 50px"
        />
      </a-col>
      <!-- <a-col :span="4" class="col">
        <a-statistic
          title="今日充值"
          :value="today_recharge"
          style="margin-right: 50px"
        />
      </a-col> -->
    </a-row>
    <a-row class="row">
      <a-col :span="4" class="col">
        <a-statistic
          title="本月保单数"
          :value="month_amount"
          style="margin-right: 50px"
        />
      </a-col>
      <a-col :span="4" class="col">
        <a-statistic
          title="本月保额"
          :value="month_money"
          style="margin-right: 50px"
        />
      </a-col>
      <a-col :span="4" class="col">
        <a-statistic
          title="本月保费"
          :value="month_fee"
          style="margin-right: 50px"
        />
      </a-col>
      <!-- <a-col :span="4" class="col">
        <a-statistic
          title="本月充值"
          :value="month_recharge"
          style="margin-right: 50px"
        />
      </a-col> -->
    </a-row>
  </div>
</template>

<style lang='scss' scoped>
.page{
  padding-top: 20px;
}
.row{
  width: 100%;
  padding: 0 20px 0 20px;
  .col{
    background: #fff;
    margin: 10px;
    padding: 20px;
  }
}
</style>

<script>
export default {
  data() {
    return {
      today_amount: 0,
      today_money: 0,
      today_fee: 0,
      today_recharge: 0,
      month_amount: 0,
      month_money: 0,
      month_fee: 0,
      month_recharge: 0
    };
  },
  async created() {
    const r = await this.$axios.post('/index');
    console.log(r);

    this.today_amount = r.data1.amount || 0;
    this.today_money = r.data1.money || 0;
    this.today_fee = r.data1.fee || 0;
    this.month_amount = r.data2.amount || 0;
    this.month_money = r.data2.money || 0;
    this.month_fee = r.data2.fee || 0;
  },
  mounted() {},
  methods: {},
};
</script>