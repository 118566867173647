<template>
  <div class="flex-page">
       <a-form class="ctrl" layout="inline">
 
      <a-form-item>
        <a-button type="primary" @click="jump('/openapi/create')">添加</a-button>
      </a-form-item>
    </a-form>


    <a-table
      class="main"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <template slot="zhuangtai" slot-scope="text, record">
        <a-tag v-if="record.status == 1" color="green">正常</a-tag>
        <a-tag v-if="record.status == 0" color="red">已删除</a-tag>
      </template>
      <template slot="action" slot-scope="text, record">
        <span>
          <router-link :to="'/openapi/edit?id=' + record.id">编辑</router-link>
        </span>
        <a-divider type="vertical" />

        <a-popconfirm
          v-if="record.status == 1"
          title="确定删除吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="doDel(record.id)"
        >
          <a href="javascript:;">删除</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <span>
          <router-link :to="'/openapi/logs?appid=' + record.id">记录</router-link>
        </span>
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="page.page_size"
      :total="page.amount"
      @change="changePage"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
import moment from 'moment'
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "名称",
    dataIndex: "name",
  },
  {
    title: "appid",
    dataIndex: "appid",
  },
  {
    title: "appsecret",
    dataIndex: "appsecret",
  },
  {
    title: "备注",
    dataIndex: "remark",
  },
  {
    title: "ip白名单",
    dataIndex: "whiteips",
  },
  {
    title: "更新时间",
    dataIndex: "update_time",
    customRender: (text, row, index) => {
        let val = row.update_time;
        return val?moment.unix(val).format('YYYY/MM/DD HH:mm:ss'):''
    }
  },
  {
    title: "添加时间",
    dataIndex: "create_time",
    customRender: (text, row, index) => {
        let val = row.create_time;
        return val?moment.unix(val).format('YYYY/MM/DD HH:mm:ss'):''
    }
  },
  {
    title: "状态",
    scopedSlots: { customRender: "zhuangtai" },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];

export default {
  data() {
    return {
      columns,
      list: [],
      options: {
        name: "",
      },
      page: {
        now_page: 1,
        page_size: 50,
        amount: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    jump(path){
        this.$router.push(path)
    },
    search() {
      this.page.now_page = 1;
      this.getList();
    },
    changePage(e) {
      this.page.now_page = e;
      this.getList();
    },

    async getList() {
      const options = { ...this.options };
      options.page = this.page.now_page;
      const r = await this.$axios.post("/openapi/getList", options);
      this.list = r.list;
    },
    async doDel(id) {
      const r = await this.$axios.post("/openapi/del", {
        id: id,
      });
      if (r.code == 0) {
        this.getList();
      }else{
          this.$message.error(r.msg);
      }
    }
  },
};
</script>