import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '../store/index'

const routes = [
  // =================== 首页 ===================
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: require('@/pages/index').default
  },

  // =================== 登录 ===================
  {
    path: '/user/login',
    name: 'login',
    meta: {
      layout: 'simple'
    },
    component: require('@/pages/user/login').default
  },
  {
    path: '/user/updatePsw',
    name: 'updatePsw',
    meta: {
      title: '修改密码'
    },
    component: require('@/pages/user/updatePsw').default
  },

  // =================== 订单管理 ===================
  {
    path: '/order/list',
    name: 'orderList',
    meta: {
      title: '订单管理'
    },
    component: require('@/pages/order/list').default
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    meta: {
      title: '订单详情'
    },
    component: require('@/pages/order/detail').default
  },



  // =================== 充值记录 ===================
  {
    path: '/recharge/list',
    name: 'rechargeList',
    meta: {
      title: '充值记录'
    },
    component: require('@/pages/recharge/list').default
  },
  {
    path: '/recharge/detail',
    name: 'rechargeDetail',
    meta: {
      title: '充值详情'
    },
    component: require('@/pages/recharge/detail').default
  },

  // =================== 客户管理 ===================
  {
    path: '/customer/create',
    name: 'customerCreate',
    meta: {
      title: '添加客户'
    },
    component: require('@/pages/customer/create').default
  },
  {
    path: '/customer/list',
    name: 'customerList',
    meta: {
      title: '客户列表'
    },
    component: require('@/pages/customer/list').default
  },
  {
    path: '/customer/info',
    name: 'customerInfo',
    meta: {
      title: '客户资料'
    },
    component: require('@/pages/customer/info').default
  },
  {
    path: '/customer/recharge',
    name: 'customerRecharge',
    meta: {
      title: '客户充值'
    },
    component: require('@/pages/customer/recharge').default
  },
  {
    path: '/customer/update',
    name: 'customerUpdate',
    meta: {
      title: '编辑客户'
    },
    component: require('@/pages/customer/update').default
  },






  // =================== 管理员 ===================
  {
    path: '/user/create',
    name: 'userCreate',
    meta: {
      title: '添加管理员'
    },
    component: require('@/pages/user/create').default
  },
  {
    path: '/user/list',
    name: 'userList',
    meta: {
      title: '管理员列表'
    },
    component: require('@/pages/user/list').default
  },
  {
    path: '/user/update',
    name: 'userUpdate',
    meta: {
      title: '修改管理员'
    },
    component: require('@/pages/user/update').default
  },

  // =================== 开放接口 ===================
  {
    path: '/openapi/list',
    name: 'openapiList',
    meta: {
      title: '开放接口账户列表'
    },
    component: require('@/pages/openapi/list').default
  },
  {
    path: '/openapi/create',
    name: 'openapiCreate',
    meta: {
      title: '添加账户列表'
    },
    component: require('@/pages/openapi/form').default
  },
  {
    path: '/openapi/edit',
    name: 'openapiEdit',
    meta: {
      title: '编辑账户列表'
    },
    component: require('@/pages/openapi/form').default
  },
  {
    path: '/openapi/logs',
    name: 'openapiLogs',
    meta: {
      title: '账户调用接口'
    },
    component: require('@/pages/openapi/logs').default
  },
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const navs = store.state.navs;
  let selectedKeys = []; //当前选中菜单
  let openKeys = []; //当前展开菜单
  const breadcrumbs = []; //面包屑

  const now_path = to.path;
  let nowNav; //当前正在运算的nav

  for (let nav of navs) {
    if (nav.path == now_path || nav.route == now_path) {
      nowNav = nav;
      break
    }
  }

  if (!nowNav) return next()

  const getFather = (id) => {
    for (let nav of navs) {
      if (nav.id == id) return nav
    }
  }

  // 根据father层层查找
  const hello = (nowNav) => {
    breadcrumbs.unshift(nowNav.title);
    if (nowNav.class == 2 && nowNav.father) selectedKeys.unshift(nowNav.id);
    if (nowNav.class == 1 && !nowNav.father) openKeys.unshift(nowNav.id);

    if (nowNav.father !== 0) {
      const father = getFather(nowNav.father);
      hello(father)
    }
  }

  if (nowNav.class == 1) {
    selectedKeys.unshift(nowNav.id);
    breadcrumbs.unshift(nowNav.title);
  }
  else {
    hello(nowNav)
  }

  store.commit('setActiveMenu', {
    selectedKeys,
    openKeys,
    breadcrumbs
  })

  next()
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
